import { Toast, ToastBody, ToastIntent, ToastTitle, Toaster, useId, useToastController } from '@fluentui/react-components';
import * as React from 'react';
import { makeObservable, observable, action, reaction } from 'mobx';

class SnackbarManagerClass {
    @observable.ref
    public state: {
        title: string;
        intent: ToastIntent;
        subTitle?: string;
    } | null = null

    constructor() {
        makeObservable(this);
    }

    @action
    public showToast(
        title: string,
        subTitle: string | undefined,
        intent: ToastIntent,
    ): void {
        this.state = { title, subTitle, intent}
    }
}

export const SnackbarManager = new SnackbarManagerClass();

export const Snackbar: React.FC = () => {
    const toasterId = useId('toaster');

    const { dispatchToast } = useToastController(toasterId);

    React.useEffect(
        () => {
            return reaction(
                () => SnackbarManager.state,
                () => {
                    if (SnackbarManager.state == null) {
                        return;
                    }

                    const { title, subTitle, intent } = SnackbarManager.state;

                    dispatchToast(
                        <Toast>
                            <ToastTitle>{title}</ToastTitle>
                            <ToastBody subtitle={subTitle}/>
                        </Toast>,
                        { intent }
                    );
                }
            )
        },
        [
            dispatchToast,
        ]
    );

    return (
        <Toaster toasterId={toasterId} />
    );
}