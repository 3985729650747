import ReactDOM from 'react-dom';
import { mergeStyles } from '@fluentui/react';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

import RecipesContext from './contexts/RecipesContext';
import { StateManager } from './managers/StateManager';
import { initInstance } from './i18n';

// Inject some global styles
mergeStyles({
    ':global(body,html,#root)': {
        margin: 0,
        padding: 0,
        height: '100vh',
        width: '100vw',
    },
    ':global(*)': {
        boxSizing: 'border-box',
    }
});

async function run() {
    await initInstance();

    const recipes = (await StateManager.getRecipes());

    StateManager.setFromHash();

    if (recipes == null) {
        return ReactDOM.render(<>failed to load file-list</>, document.getElementById('root'));
    }

    const App = (await import('./App')).default;

    ReactDOM.render(
        <FluentProvider theme={teamsLightTheme}>
            <RecipesContext.Provider value={recipes}>
                <App/>
            </RecipesContext.Provider>
        </FluentProvider>,
        document.getElementById('root')
    );
}

void run();