import { hash } from 'spark-md5';
import { makeObservable, observable, action } from 'mobx';

import { Recipe, RecipeType, RecipesContextValue } from '../contexts/RecipesContext';
import { getContent, loadRecipeJSON } from '../Actions';
import { withProgress } from './ProgressManager';

export interface StateManagerState {
    category: RecipeType | string | null;
    recipe: (Recipe & { content?: string }) | null;
}

export class StateManagerClass {
    private recipes: RecipesContextValue = {};

    @observable
    public state: StateManagerState = {
        category: null,
        recipe: null,
    }

    constructor() {
        makeObservable(this);
        this.setCategory = this.setCategory.bind(this);
        this.setRecipe = this.setRecipe.bind(this);
    }

    public async setFromHash() {
        const hashInUrl = /#([^&]*)/.exec(window.location.hash);
        if (hashInUrl && hashInUrl[1]) {
            for (const category of Object.keys(this.recipes)) {
                for (const recipe of this.recipes[category]) {
                    if (hash(JSON.stringify({ category, recipe })) === hashInUrl[1]) {
                        return this.updateState({
                            category,
                            recipe: {
                                ...recipe,
                                content: await getContent(recipe)
                            },
                        });
                    }
                }
            }
        }
    }

    @action
    public updateState(state: Partial<StateManagerState>): void {
        this.state = {...this.state, ...state};

    }

    public setCategory(category: StateManagerState['category']): void {
        this.updateState({ category, recipe: null });
    }

    @withProgress('setRecipe')
    public async setRecipe(recipe: StateManagerState['recipe']): Promise<void> {
        if (recipe?.url == null || recipe.url === '') {
            window.location.hash = '';
            return this.updateState({ recipe: null });
        }

        window.location.hash = `#${hash(JSON.stringify({ category: this.state.category, recipe }))}`;
        this.updateState({ recipe: {
            ...recipe,
            content: await getContent(recipe)
        } });
    }

    @withProgress('getRecipes')
    public async getRecipes(): Promise<RecipesContextValue | null> {
        const recipes = await loadRecipeJSON();

        this.recipes = recipes ?? {};

        return recipes;
    }
}

export const StateManager = new StateManagerClass();