import i18next from 'i18next';
import { hash } from 'spark-md5';

import type { Recipe, RecipesContextValue } from './contexts/RecipesContext';
import type { StateManagerState } from './managers/StateManager';
import { SnackbarManager } from './managers/SnackbarManager';

export async function loadRecipeJSON(): Promise<RecipesContextValue | null> {
    let recipes: RecipesContextValue;

    try {
        recipes = await (await fetch('./downloads/file-list.json')).json();
    } catch {
        return null;
    }

    let recipesFiltered: RecipesContextValue = {};

    for (const recipeType of Object.keys(recipes)) {
        if (!recipes[recipeType]?.length) {
            continue;
        }
        recipesFiltered[recipeType] = recipes[recipeType]
    }

    return recipesFiltered;
}

export function copyLink(
    category: StateManagerState['category'],
    recipe: StateManagerState['recipe']
): void {
    try {
        navigator.clipboard.writeText(`https://daniel-rabe.com/#${hash(JSON.stringify({ category, recipe }))}`)
        SnackbarManager.showToast(i18next.t('Link copied to clipboard'), recipe?.name, 'success');
    } catch {
        SnackbarManager.showToast(i18next.t('Copy failed'), recipe?.name, 'error');
    }
}

const cache: Map<string, string> = new Map();

export async function getContent(recipe: Recipe): Promise<string | undefined> {
    if (recipe.type !== 'md') {
        return;
    }

    if (cache.has(recipe.url)) {
        return cache.get(recipe.url);
    }

    try {
        return await (await fetch(recipe.url)).text();
    } catch {
        return;
    }
}