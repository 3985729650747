import { action, computed, observable, makeObservable } from 'mobx';

export class ProgressManagerClass {
    @computed
    public get inProgress(): boolean {
        return this.lockCount !== 0;
    }

    @observable
    protected lockCount: number = 0;

    constructor(protected progressDelay: number = 0) {
        makeObservable(this);
        this.startProgress = this.startProgress.bind(this);
        this.finishProgress = this.finishProgress.bind(this);
    }

    @action
    public startProgress(): void {
        this.lockCount++;
    }

    @action
    public finishProgress(): void {
        this.lockCount--;
    }

    public withInstantProgress = async <T>(debugLabel: string, promise: () => Promise<T>, ignoreProgress: boolean = false): Promise<T> => {
        let delayedProgressTimeout: number | undefined;
        if (ignoreProgress !== true) {
            if (this.progressDelay > 0) {
                delayedProgressTimeout = window.setTimeout(
                    () => {
                        delayedProgressTimeout = undefined;
                        this.startProgress();
                    },
                    this.progressDelay
                );
            } else {
                this.startProgress();
            }
        }

        try {
            return await promise();
        } finally {
            if (ignoreProgress !== true) {
                if (delayedProgressTimeout != null) {
                    clearTimeout(delayedProgressTimeout);
                } else {
                    this.finishProgress();
                }
            }
        }
    };
}

export const ProgressManager: ProgressManagerClass = new ProgressManagerClass();

type PromiseMethodDecorator<T = any> = (
    target: object,
    propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<(...args: Array<any>) => Promise<T>>
) => TypedPropertyDescriptor<(...args: Array<any>) => Promise<T>> | void;

/* eslint-disable @typescript-eslint/no-explicit-any */
export function withProgress<T>(
    label: string,
    customManager: ProgressManagerClass = ProgressManager,
    ignoreProgress?: (self: unknown) => boolean
): PromiseMethodDecorator {
    return (
        target: object,
        propertyKey: string | symbol,
        descriptor: TypedPropertyDescriptor<(...args: Array<any>) => Promise<T>>
    ) => {
        const method: ((...args: Array<any>) => Promise<T>) | undefined = descriptor.value;
        if (method !== undefined) {
            descriptor.value = function(...args: Array<any>): Promise<T> {
                return customManager.withInstantProgress(
                    label,
                    (): Promise<T> => {
                        return method.apply(this, args);
                    },
                    ignoreProgress?.(this)
                );
            };
        }
        return descriptor;
    };
}
/* eslint-enable @typescript-eslint/no-explicit-any */