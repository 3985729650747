import LanguageDetector from 'i18next-browser-languagedetector';
import i18next, { BackendModule, ResourceLanguage, InitOptions, i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';

const webPackBackend: BackendModule = {
    type: 'backend',
    init: () => {/**/},
    create: () => {/**/},
    async read(
        language: string,
        namespace: string,
        callback: (err: Error | null | undefined, data: ResourceLanguage) => void
    ): Promise<void> {
        try {
            const translation: ResourceLanguage = await import(
                 /* webpackChunkName: "i18n" */
                `./i18n/${language}/${namespace}.json`
            );

            callback(null, translation);
        } catch (error) {
            callback(error as Error, {});
        }
    }
};

const i18nextInstance: i18n = i18next.use(webPackBackend).use(LanguageDetector).use(initReactI18next);

export async function initInstance(): Promise<i18n> {
    const initOptions: InitOptions = {
        fallbackLng: 'en',
        returnEmptyString: false,
        supportedLngs: ['de', 'en'],
        ns: ['translation'],
        load: 'languageOnly',
        defaultNS: 'translation',
        keySeparator: '::',
        nsSeparator: '__SS',
        react: {
            bindI18n: 'languageChanged loaded',
            useSuspense: false,
        },
    };

    await i18nextInstance.init(initOptions);
    return i18nextInstance;
}

export async function setLanguage(language: string): Promise<void> {
    await i18next.changeLanguage(language);
}

export default i18nextInstance;
