import React from "react";

export const enum RecipeType {
    BREAKFAST = 'breakfast',
    LUNCH = 'lunch',
    DINNER = 'dinner',
    MISC = 'misc',
    SAUCES = 'sauces',
    BAKE = 'bake'
}

export interface Recipe {
    name: string;
    url: string;
    type: string;
}

export interface RecipesProps {
    type: RecipeType | string | null;
}

export type RecipesContextValue = { [key: string | RecipeType]: Array<Recipe> };

export default React.createContext<RecipesContextValue>({});